import { ActionFunctionArgs } from '@remix-run/node';
import { InputField } from '~/front/components/InputField';

import { z } from 'zod';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { getFormProps, useForm } from '@conform-to/react';
import { FormErrors } from '~/front/components/Form/FormErrors';
import { FormSubmitButton } from '~/front/components/Form/FormSubmitButton';
import UCFetcherForm from '~/front/components/Form/UCFetcherForm';
import {
  typedjson,
  useTypedFetcher,
  useTypedLoaderData,
} from 'remix-typedjson';
import { FormLoading } from '~/front/components/Form/FormLoading';
import React, { useState, useCallback } from 'react';
import RecaptchaCheckBox from '~/front/components/RecaptchaCheckBox';
import { RECAPTCHA_ENABLED } from '~/server/utils/verify_recaptcha.server';
import { loginAction } from '~/server/models/admin/presentation/loginAction.server';
import { remixActionUserContextMiddleware } from '~/server/utils/context.server';
import { UsersLayout } from '~/front/components/Users/UsersLayout';

export const loginSchema = (recaptchaEnabled: boolean) =>
  z.object({
    email: z
      .string({ required_error: 'メールアドレスは必須です' })
      .email('メールアドレスが不正です'),
    password: z
      .string({ required_error: 'パスワードは必須です' })
      .min(6, 'パスワードが短すぎます（6文字以上）')
      .max(20, 'パスワードが長すぎます（20文字以内）'),
    intent: z.string({ required_error: 'intentが存在しません' }),
    ...(recaptchaEnabled ? { recaptcha_token: z.string() } : {}),
  });

enum Actions {
  LOGIN = 'login',
}

export const action = remixActionUserContextMiddleware(
  async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    switch (intent) {
      case Actions.LOGIN:
        return loginAction({ request, formData });
      default:
        throw new Response(`Invalid intent "${intent}"`, { status: 400 });
    }
  },
);

export const loader = async () => {
  return typedjson({
    recaptcha_enabled: RECAPTCHA_ENABLED,
  });
};

const MemorizedRecaptcha = React.memo(RecaptchaCheckBox);

const loginFetcherKey = 'login';
export default function Index() {
  const fetcher = useTypedFetcher<typeof loginAction>({ key: loginFetcherKey });
  const loading = fetcher.state !== 'idle';
  return (
    <>
      <UsersLayout title="アカウントにログイン">
        <LoginForm />
      </UsersLayout>
      {loading && <FormLoading />}
    </>
  );
}

function LoginForm() {
  const { recaptcha_enabled } = useTypedLoaderData<typeof loader>();
  const fetcher = useTypedFetcher<typeof loginAction>({ key: loginFetcherKey });
  const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaReloadKey, setRecaptchaReloadKey] = useState(0);

  const handleVerifyRecaptcha = useCallback(async (recaptchaToken: string) => {
    setRecaptchaVerified(true);
    setRecaptchaToken(recaptchaToken);
  }, []);

  const handleRecaptchaExpired = useCallback(() => {
    setRecaptchaVerified(false);
  }, []);

  const [form, fields] = useForm({
    lastResult: fetcher.data?.parseResult,
    constraint: getZodConstraint(loginSchema(recaptcha_enabled)),
    onSubmit: () => {
      setRecaptchaVerified(false);
      setRecaptchaReloadKey(recaptchaReloadKey + 1);
    },
    shouldValidate: 'onBlur',
    shouldRevalidate: 'onInput',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: loginSchema(recaptcha_enabled) });
    },
  });

  const loginButtonDisabled = recaptcha_enabled ? !recaptchaVerified : false;

  return (
    <>
      <UCFetcherForm
        className="flex flex-col gap-6 mt-9"
        method="post"
        formProps={getFormProps(form)}
        fetcher={fetcher}
      >
        <InputField
          label="メールアドレス"
          type="email"
          id={fields.email.id}
          name={fields.email.name}
          errors={fields.email.errors}
        />
        <InputField
          label="パスワード"
          type="password"
          id={fields.password.id}
          name={fields.password.name}
          errors={fields.password.errors}
        />
        <input
          type="hidden"
          id={fields.recaptcha_token.id}
          name={fields.recaptcha_token.name}
          value={recaptchaToken}
        />
        {recaptcha_enabled && (
          <MemorizedRecaptcha
            handleVerifyRecaptcha={handleVerifyRecaptcha}
            handleRecaptchaExpired={handleRecaptchaExpired}
            action="login_admin"
            reloadKey={recaptchaReloadKey}
          />
        )}
        <div>
          <FormSubmitButton
            label="ログイン"
            intentName={Actions.LOGIN}
            isLoading={false}
            fullWidth={true}
            disabled={loginButtonDisabled}
          />
          {form.errors && <FormErrors errors={form.errors ?? []} />}
        </div>
      </UCFetcherForm>
    </>
  );
}
